<script setup>
import dayjs from 'dayjs'
import 'dayjs/locale/id'

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
})

dayjs.locale('id')

function rupiahSymbol(amount) {
  if (!amount)
    return '-'
  return `Rp. ${amount.toLocaleString('id-ID')}`
}

function formatContent(item) {
  if (typeof item.content === 'boolean')
    return item.content ? 'Iya' : 'Tidak'

  if (item.isMatch !== undefined)
    return item.content === item.isMatch ? 'Iya' : 'Tidak'

  if (item.append) {
    if (item.append === 'rupiah') {
      if (item.content === null || item.content === undefined)
        return '-'
      else if (item.content !== '-')
        return rupiahSymbol(Number(item.content))
    }
    else {
      return item.append + item.content
    }
  }

  if (item.prepend)
    return item.content + item.prepend

  if (item.content === null || item.content === undefined || item.content === '')
    return '-'

  if (item.type === 'month')
    return dayjs().month(item.content - 1).format('MMMM ')
  if (item.type === 'day')
    return dayjs().day(item.content - 1).format('dddd ')
  if (item.type === 'year')
    return `${item.content.toString()}`
  if (item.type === 'date')
    return dayjs(item.content).format('DD-MM-YYYY')
  if (item.type === 'datetime')
    return dayjs(item.content).format('DD-MM-YYYY HH:mm:ss')

  return item.content
}

const transformedData = computed(() => {
  return props.data.filter(item => item.isShow).map((item) => {
    if (item.contentItems) {
      item.contentItems = item.contentItems.map(contentItem => ({
        ...contentItem,
        content: formatContent(contentItem),
      }))
    }
    return item
  })
})
</script>

<template>
  <div class="grid grid-cols-2 gap-1 sm:grid-cols-1 md:grid-cols-2">
    <div v-for="(item, index) in transformedData" :key="index">
      <div v-if="!item.contentItems.some(contentItem => contentItem.type === 'slot')" class="text-header-gray">
        {{ item.title }}
      </div>
      <div class="text-content mb-[24px]">
        <template v-for="(contentItem, contentIndex) in item.contentItems" :key="contentIndex">
          <KBadge v-if="contentItem.type === 'badge'">
            {{ contentItem.content }}
          </KBadge>
          <span v-else-if="contentItem.type !== 'slot'">{{ contentItem.content }}</span>
          <slot v-else :name="`slot-${item.title}`" />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.text-heder{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.text-header-gray{
  @apply text-[#8B8B8B] mb-[4px];
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.text-content{
  @apply text-[#2A2A2A] ;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.text-content-500{
  @apply text-[#2A2A2A] ;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
</style>
